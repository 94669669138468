import React from "react";
import classes from "./Footer.module.scss";
import award_01 from '../../../assets/img/Footer/award_01.png';
import award_02 from '../../../assets/img/Footer/award_02.png';
import award_03 from '../../../assets/img/Footer/award_03.png';
import logo from '../../../assets/img/logo.png';
import { Link } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();
  const { hostname } = document.location;
  return (
    <footer className={classes.footer}>
      <div className="container">
        <div className={classes.footerBody}>
          <div className={classes.docs}>
            <Link to='/'>
              <img className={classes.logo} src={logo} alt=''/>
            </Link>
            <div className={classes.docsBody}>
              <a href={`mailto:support@${hostname}`}>
                support@{hostname}
              </a>
              <a href="tel:+441241340522">+441241340522</a>
              <a href="/docs/TERMS_&_CONDITIONS.pdf" target="_blank">{t('footer_terms_conditions')}</a>
              <a href="/docs/PRIVACY_POLICY.pdf" target="_blank">{t('footer_privacy_policy')}</a>
              <a href="/docs/AML_POLICY.pdf" target="_blank">{t('footer_aml_policy')}</a>
            </div>
          </div>
          <div className={classes.awards}>
            <div className={classes.awardsBody}>
              <img className={classes.award_01} src={award_01} alt=''/>
              <img className={classes.award_02} src={award_02} alt=''/>
              <img className={classes.award_03} src={award_03} alt=''/>
            </div>
            <p className={classes.awardsText}>
              <Trans>
                {t('footer_award_text')}
              </Trans>
            </p>
          </div>
          <div className={classes.disclaimer}>
            <div className={classes.disclaimerTitle}>
              {t('footer_disclaimer')}
            </div>
            <div className={classes.disclaimerText}>
              <p>
                {t('footer_disclaimer_text_01')}
              </p>
              <p>
                {t('footer_disclaimer_text_02')}
              </p>
            </div>
            <div className={classes.rights}>
              {t('footer_rights')}
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
};

export default Footer;
