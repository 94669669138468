import React from "react";
import classes from "./Button.module.scss";
import Cookies from 'js-cookie';

const Button = (props) => {
  const lang = Cookies.get('i18next');
  return (
    <>
      {props.login === "true" ?
        <a href={`https://user.gainground.tech/login?lang=${lang}`} {...props} className={classes.button}>
          <span className={classes.btnsvg__label}>{props.children}</span>
        </a>
        :
        <a href={`https://user.gainground.tech/signup?lang=${lang}`} {...props} className={classes.button}>
          <span className={classes.btnsvg__label}>{props.children}</span>
        </a>
      }
    </>
  );
};

export default Button;
